
const request = function(method: string, url: string, body: any, header: any, cb: any, isSync?: boolean, noLoading?: boolean) {
    noLoading = noLoading || noLoading == undefined ? true : false;// NOTE: 默认true
    body = body || {};
  
    header = header|| {};
    const http = new XMLHttpRequest(); // XMLHttpRequest 对象
    http.open(method, url, !isSync); //post方式，url为服务器请求地址，true 该参数规定请求是否异步处理。
      http.setRequestHeader("app_id", "101");
      for (const key in header) {
      http.setRequestHeader(key, header[key]);
    }
  
    http.setRequestHeader("Content-type", "application/json;charset=UTF-8");
    http.onload = function(e: any) {
      let data;
      try {
        const res = JSON.parse(e.target.responseText);
        data = res.result || res;
        data = JSON.parse(JSON.stringify(data))
        // .replace(new RegExp('http://static.d.intbee.com/', 'g'), 'https://test-qiniu.intbee.com/')
        // .replace(new RegExp('http://static.intbee.com/', 'g'), 'https://qiniu-static.intbee.com/'))
      } catch (err) {
        cb(e.target.responseText);
        console.error(err);
      }
      cb(data);
    }; //请求完成
    http.onerror = function(error) {
      cb(error);
    }; //请求失败
    http.send(body instanceof FormData ? body : JSON.stringify(body));
  };
  export const http = {
    get: function(url: string, body: any, header: any, cb: any, isSync?: boolean, noLoading?: boolean) {
      return request("GET", url, body, header, cb, isSync, noLoading);
    },
    put: function(url: string, body: any, header: any, cb: any, isSync?: boolean, noLoading?: boolean) {
      return request("PUT", url, body, header, cb, isSync, noLoading);
    },
    post: function(url: string, body: any, header: any, cb: any, isSync?: boolean, noLoading?: boolean) {
      return request("POST", url, body, header, cb, isSync, noLoading);
    },
    del: function(url: string, body: any, header: any, cb: any, isSync?: boolean, noLoading?: boolean) {
      return request("DELETE", url, body, header, cb, isSync, noLoading);
    }
  }

export const loading = {
    open: function() {
        const loading = document.getElementById("bodyLoading")
      if (!loading) {
        const node = document.createElement("div");
        node.id = "bodyLoading"
        node.appendChild(document.createElement("div"))
        document.body.appendChild(node)
      }
    },
    close: function() {
        const loading = document.getElementById("bodyLoading")
      if (loading) {
        document.body.removeChild(loading)
      }
    },
    opening: function() {
      return document.getElementById("bodyLoading");
    }
  };
  export const toast = function (msg: string, time?: number) {
    time = time || 3000;
    const dismissTime = 300
    const node: HTMLDivElement = document.createElement("div");
    node.className = "bodyToast"
    node.innerHTML = msg
    document.body.appendChild(node);
    setTimeout(function () {
      node.setAttribute('class', "bodyToast dismiss")
      setTimeout(function () {
        document.body.removeChild(node);
      }, dismissTime);
    }, time - dismissTime);
  }