import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import AppLayout from "../views/AppLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/help",
    name: "HelpCenter",
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/Help.vue"),
  },
  {
    path: "/*",
    name: "AppLayout",
    component: AppLayout,
    children: [
      {
        path: "/product",
        name: "Product",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "product" */ "../views/Product.vue"),
      },
      {
        path: "/brands",
        name: "Brands",
        component: () =>
          import(/* webpackChunkName: "brands" */ "../views/Brands.vue"),
      },
      {
        path: "/brands/detail",
        name: "BrandDetail",
        component: () =>
          import(/* webpackChunkName: "brands" */ "../views/BrandDetail.vue"),
      },
      {
        path: "/about/detail",
        name: "TrendDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/TrendDetail.vue"),
      },
      {
        path: "/concat",
        name: "Concat",
        component: () =>
          import(/* webpackChunkName: "concat" */ "../views/Concat.vue"),
      },
      {
        path: "/about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        name: "Home",
        component: Home,
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
