<template>
  <router-view />
</template>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {
  text-decoration: none;
  color: inherit;
}
.im-server {
  display: none!important;;
}
/* body {
  min-width: 1200px;
} */
body,ul,li {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: PingFang SC;
}
input,button {
  outline: none;
  border: 0;
}
button {
  cursor: pointer;
}
// .container {
//   width: 1200px;
//   margin: auto;
// }
img {
  vertical-align: middle;
}
* {
  box-sizing: border-box;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width:992px) {.col-pc-1 {width:4.1666666667%;}
.col-pc-2 {width:8.3333333333%;}
.col-pc-3 {width:12.5%;}
.col-pc-4 {width:16.6666666667%;}
.col-pc-5 {width:20.8333333333%;}
.col-pc-6 {width:25%;}
.col-pc-7 {width:29.1666666667%;}
.col-pc-8 {width:33.3333333333%;}
.col-pc-9 {width:37.5%;}
.col-pc-10 {width:41.6666666667%;}
.col-pc-11 {width:45.8333333333%;}
.col-pc-12 {width:50%;}
.col-pc-13 {width:54.1666666667%;}
.col-pc-14 {width:58.3333333333%;}
.col-pc-15 {width:62.5%;}
.col-pc-16 {width:66.6666666667%;}
.col-pc-17 {width:70.8333333333%;}
.col-pc-18 {width:75%;}
.col-pc-19 {width:79.1666666667%;}
.col-pc-20 {width:83.3333333333%;}
.col-pc-21 {width:87.5%;}
.col-pc-22 {width:91.6666666667%;}
.col-pc-23 {width:95.8333333333%;}
.col-pc-24 {width:100%;}
}
@media (max-width:1200px) {.col-h5-1 {width:4.1666666667%;}
.col-h5-2 {width:8.3333333333%;}
.col-h5-3 {width:12.5%;}
.col-h5-4 {width:16.6666666667%;}
.col-h5-5 {width:20.8333333333%;}
.col-h5-6 {width:25%;}
.col-h5-7 {width:29.1666666667%;}
.col-h5-8 {width:33.3333333333%;}
.col-h5-9 {width:37.5%;}
.col-h5-10 {width:41.6666666667%;}
.col-h5-11 {width:45.8333333333%;}
.col-h5-12 {width:50%;}
.col-h5-13 {width:54.1666666667%;}
.col-h5-14 {width:58.3333333333%;}
.col-h5-15 {width:62.5%;}
.col-h5-16 {width:66.6666666667%;}
.col-h5-17 {width:70.8333333333%;}
.col-h5-18 {width:75%;}
.col-h5-19 {width:79.1666666667%;}
.col-h5-20 {width:83.3333333333%;}
.col-h5-21 {width:87.5%;}
.col-h5-22 {width:91.6666666667%;}
.col-h5-23 {width:95.8333333333%;}
.col-h5-24 {width:100%;}
}
.p-5 {padding:5px;}
.pt-5 {padding-top:5px;}
.pb-5 {padding-bottom:5px;}
.pl-5 {padding-left:5px;}
.pl-5 {padding-right:5px;}
.m-5 {margin:5px;}
.mt-5 {margin-top:5px;}
.mb-5 {margin-bottom:5px;}
.ml-5 {margin-left:5px;}
.ml-5 {margin-right:5px;}
.p-10 {padding:10px;}
.pt-10 {padding-top:10px;}
.pb-10 {padding-bottom:10px;}
.pl-10 {padding-left:10px;}
.pl-10 {padding-right:10px;}
.m-10 {margin:10px;}
.mt-10 {margin-top:10px;}
.mb-10 {margin-bottom:10px;}
.ml-10 {margin-left:10px;}
.ml-10 {margin-right:10px;}
.p-15 {padding:15px;}
.pt-15 {padding-top:15px;}
.pb-15 {padding-bottom:15px;}
.pl-15 {padding-left:15px;}
.pl-15 {padding-right:15px;}
.m-15 {margin:15px;}
.mt-15 {margin-top:15px;}
.mb-15 {margin-bottom:15px;}
.ml-15 {margin-left:15px;}
.ml-15 {margin-right:15px;}
.p-20 {padding:20px;}
.pt-20 {padding-top:20px;}
.pb-20 {padding-bottom:20px;}
.pl-20 {padding-left:20px;}
.pl-20 {padding-right:20px;}
.m-20 {margin:20px;}
.mt-20 {margin-top:20px;}
.mb-20 {margin-bottom:20px;}
.ml-20 {margin-left:20px;}
.ml-20 {margin-right:20px;}
.p-25 {padding:25px;}
.pt-25 {padding-top:25px;}
.pb-25 {padding-bottom:25px;}
.pl-25 {padding-left:25px;}
.pl-25 {padding-right:25px;}
.m-25 {margin:25px;}
.mt-25 {margin-top:25px;}
.mb-25 {margin-bottom:25px;}
.ml-25 {margin-left:25px;}
.ml-25 {margin-right:25px;}
.p-30 {padding:30px;}
.pt-30 {padding-top:30px;}
.pb-30 {padding-bottom:30px;}
.pl-30 {padding-left:30px;}
.pl-30 {padding-right:30px;}
.m-30 {margin:30px;}
.mt-30 {margin-top:30px;}
.mb-30 {margin-bottom:30px;}
.ml-30 {margin-left:30px;}
.ml-30 {margin-right:30px;}
.p-35 {padding:35px;}
.pt-35 {padding-top:35px;}
.pb-35 {padding-bottom:35px;}
.pl-35 {padding-left:35px;}
.pl-35 {padding-right:35px;}
.m-35 {margin:35px;}
.mt-35 {margin-top:35px;}
.mb-35 {margin-bottom:35px;}
.ml-35 {margin-left:35px;}
.ml-35 {margin-right:35px;}
.p-40 {padding:40px;}
.pt-40 {padding-top:40px;}
.pb-40 {padding-bottom:40px;}
.pl-40 {padding-left:40px;}
.pl-40 {padding-right:40px;}
.m-40 {margin:40px;}
.mt-40 {margin-top:40px;}
.mb-40 {margin-bottom:40px;}
.ml-40 {margin-left:40px;}
.ml-40 {margin-right:40px;}
.p-45 {padding:45px;}
.pt-45 {padding-top:45px;}
.pb-45 {padding-bottom:45px;}
.pl-45 {padding-left:45px;}
.pl-45 {padding-right:45px;}
.m-45 {margin:45px;}
.mt-45 {margin-top:45px;}
.mb-45 {margin-bottom:45px;}
.ml-45 {margin-left:45px;}
.ml-45 {margin-right:45px;}
.p-50 {padding:50px;}
.pt-50 {padding-top:50px;}
.pb-50 {padding-bottom:50px;}
.pl-50 {padding-left:50px;}
.pl-50 {padding-right:50px;}
.m-50 {margin:50px;}
.mt-50 {margin-top:50px;}
.mb-50 {margin-bottom:50px;}
.ml-50 {margin-left:50px;}
.ml-50 {margin-right:50px;}
.p-4 {padding:4px;}
.pt-4 {padding-top:4px;}
.pb-4 {padding-bottom:4px;}
.pl-4 {padding-left:4px;}
.pl-4 {padding-right:4px;}
.m-4 {margin:4px;}
.mt-4 {margin-top:4px;}
.mb-4 {margin-bottom:4px;}
.ml-4 {margin-left:4px;}
.ml-4 {margin-right:4px;}
.p-8 {padding:8px;}
.pt-8 {padding-top:8px;}
.pb-8 {padding-bottom:8px;}
.pl-8 {padding-left:8px;}
.pl-8 {padding-right:8px;}
.m-8 {margin:8px;}
.mt-8 {margin-top:8px;}
.mb-8 {margin-bottom:8px;}
.ml-8 {margin-left:8px;}
.ml-8 {margin-right:8px;}
.p-12 {padding:12px;}
.pt-12 {padding-top:12px;}
.pb-12 {padding-bottom:12px;}
.pl-12 {padding-left:12px;}
.pl-12 {padding-right:12px;}
.m-12 {margin:12px;}
.mt-12 {margin-top:12px;}
.mb-12 {margin-bottom:12px;}
.ml-12 {margin-left:12px;}
.ml-12 {margin-right:12px;}
.p-16 {padding:16px;}
.pt-16 {padding-top:16px;}
.pb-16 {padding-bottom:16px;}
.pl-16 {padding-left:16px;}
.pl-16 {padding-right:16px;}
.m-16 {margin:16px;}
.mt-16 {margin-top:16px;}
.mb-16 {margin-bottom:16px;}
.ml-16 {margin-left:16px;}
.ml-16 {margin-right:16px;}
.p-20 {padding:20px;}
.pt-20 {padding-top:20px;}
.pb-20 {padding-bottom:20px;}
.pl-20 {padding-left:20px;}
.pl-20 {padding-right:20px;}
.m-20 {margin:20px;}
.mt-20 {margin-top:20px;}
.mb-20 {margin-bottom:20px;}
.ml-20 {margin-left:20px;}
.ml-20 {margin-right:20px;}
.p-24 {padding:24px;}
.pt-24 {padding-top:24px;}
.pb-24 {padding-bottom:24px;}
.pl-24 {padding-left:24px;}
.pl-24 {padding-right:24px;}
.m-24 {margin:24px;}
.mt-24 {margin-top:24px;}
.mb-24 {margin-bottom:24px;}
.ml-24 {margin-left:24px;}
.ml-24 {margin-right:24px;}
.p-28 {padding:28px;}
.pt-28 {padding-top:28px;}
.pb-28 {padding-bottom:28px;}
.pl-28 {padding-left:28px;}
.pl-28 {padding-right:28px;}
.m-28 {margin:28px;}
.mt-28 {margin-top:28px;}
.mb-28 {margin-bottom:28px;}
.ml-28 {margin-left:28px;}
.ml-28 {margin-right:28px;}
.p-32 {padding:32px;}
.pt-32 {padding-top:32px;}
.pb-32 {padding-bottom:32px;}
.pl-32 {padding-left:32px;}
.pl-32 {padding-right:32px;}
.m-32 {margin:32px;}
.mt-32 {margin-top:32px;}
.mb-32 {margin-bottom:32px;}
.ml-32 {margin-left:32px;}
.ml-32 {margin-right:32px;}
.p-36 {padding:36px;}
.pt-36 {padding-top:36px;}
.pb-36 {padding-bottom:36px;}
.pl-36 {padding-left:36px;}
.pl-36 {padding-right:36px;}
.m-36 {margin:36px;}
.mt-36 {margin-top:36px;}
.mb-36 {margin-bottom:36px;}
.ml-36 {margin-left:36px;}
.ml-36 {margin-right:36px;}
.p-40 {padding:40px;}
.pt-40 {padding-top:40px;}
.pb-40 {padding-bottom:40px;}
.pl-40 {padding-left:40px;}
.pl-40 {padding-right:40px;}
.m-40 {margin:40px;}
.mt-40 {margin-top:40px;}
.mb-40 {margin-bottom:40px;}
.ml-40 {margin-left:40px;}
.ml-40 {margin-right:40px;}
.font-20 {font-size:20px;}
.font-22 {font-size:22px;}
.font-24 {font-size:24px;}
.font-26 {font-size:26px;}
.font-28 {font-size:28px;}
.font-30 {font-size:30px;}
.font-32 {font-size:32px;}
.font-34 {font-size:34px;}
.font-36 {font-size:36px;}
.font-38 {font-size:38px;}
.font-40 {font-size:40px;}
.font-42 {font-size:42px;}
.font-44 {font-size:44px;}
.font-46 {font-size:46px;}
.font-48 {font-size:48px;}
.font-50 {font-size:50px;}
.font-52 {font-size:52px;}
.font-54 {font-size:54px;}
.font-56 {font-size:56px;}
.font-58 {font-size:58px;}
.font-60 {font-size:60px;}
.font-62 {font-size:62px;}
.font-64 {font-size:64px;}
.font-66 {font-size:66px;}
.font-68 {font-size:68px;}
.font-70 {font-size:70px;}
.c-ccc{color:rgba(0, 0, 0, 0.26)}
.c-999{color:rgba(0, 0, 0, 0.4)}
.c-666{color:rgba(0, 0, 0, 0.6)}
.c-444{color:rgba(0, 0, 0, 0.8)}
.c-222{color:rgba(0, 0, 0, 0.9)}
.c-b-26{color:rgba(0, 0, 0, 0.26)}
.c-b-4{color:rgba(0, 0, 0, 0.4)}
.c-b-6{color:rgba(0, 0, 0, 0.6)}
.c-b-8{color:rgba(0, 0, 0, 0.8)}
.c-b-9{color:rgba(0, 0, 0, 0.9)}
.c-w-26{color:rgba(255, 255, 255, 0.26)}
.c-w-4{color:rgba(255, 255, 255, 0.4)}
.c-w-6{color:rgba(255, 255, 255, 0.6)}
.c-w-8{color:rgba(255, 255, 255, 0.8)}
.c-w-9{color:rgba(255, 255, 255, 0.9)}
.c-555{color:#555}
.c-fff{color:#fff}
.c-000{color:#000}
.c-red{color:#f24c38}
.flex{display:flex}
.flex1{flex:1}
.flex-wrap{display:flex;flex-wrap:wrap}
.flex-center{display:flex;align-items:center;justify-content:center}
.justify-center{display:flex;justify-content:center}
.justify-around{display:flex;justify-content:space-around}
.justify-between{display:flex;justify-content:space-between}
.justify-end{display:flex;justify-content:flex-end;}
.align-center{display:flex;align-items:center}
.align-end{display:flex;align-items:flex-end}
.align-start{display:flex;align-items:flex-start}
.text-center{text-align:center}
.text-left{text-align:left}
.text-right{text-align:right}
.weight-bold{font-weight:bold}
.weight-400{font-weight:400}
.weight-500{font-weight:500}
.fixed-bottom{position:fixed;bottom:0;left:0;width:100%}
.send-input{background:white;height:2.5rem;line-height:2.5rem;border-radius:2.5rem;border:0.0625rem solid #ddd;padding:0 0.625rem;width:100%;font-size:0.875rem}
.bg-white{background:white}
.text-ell{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.ellipsis{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.ellipsis2{text-overflow:-o-ellipsis-lastline;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;line-clamp:2;-webkit-box-orient:vertical}
.ellipsis3{text-overflow:-o-ellipsis-lastline;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:3;line-clamp:3;-webkit-box-orient:vertical}



.img-block {
  background: #F5F5F5;
}
</style>
<style>
#bodyLoading{position:fixed;z-index:9999;top:0;left:0;width:100%;height:100%}
#bodyLoading>div{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background:rgba(0,0,0,0.7);border-radius:10PX;padding:14PX;text-align:center}
#bodyLoading>div::after{display:block;content:" ";height:32PX;width:32PX;-moz-animation:my-rotate 0.8s infinite linear;-o-animation:my-rotate 0.8s infinite linear;-ms-animation:my-rotate 0.8s infinite linear;-webkit-animation:my-rotate 0.8s infinite linear;animation:my-rotate 0.8s infinite linear;border:4PX solid rgb(204,204,204);border-right:4PX solid transparent;border-radius:50%}
@keyframes my-rotate {
  form{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0)}
  to{-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg)}
}
.bodyToast{position:fixed;z-index:9999;top:50%;left:50%;transform:translate(-50%,-50%);background:rgba(0,0,0,0.7);color:white;border-radius:5PX;padding:10PX 10PX;transition:opacity .3s;opacity:1;text-align:center}
.bodyToast .dismiss{opacity:0}
</style>
