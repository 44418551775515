<template>
    <div class="page">
        <div class="container">
            <div class="block1">
                <div class="block-title">扫码加微信</div>
                <img class="block1-img" src="../assets/qrcode.png" alt="" />
            </div>
            <div class="block2">
                <div class="block-title">联系我们</div>
                <div class="block2-info">
                    <div>商务合作：136-9210-4455</div>
                    <div>联系电话：0756-2608983</div>
                    <!-- 0756-2608886 -->
                    <div>反馈邮箱：service&#64;intbee.com</div>
                    <div>珠海公司地址：珠海市横琴新区环岛路ICC国际商务中心1座 721</div>
                </div>
            </div>
            <div class="block3">
                <img src="../assets/logo_white.png" alt="" />
                <div class="block3-bottom">
                    <div class="block3-input">
                        <img src="../assets/icon_name.png" alt="" />
                        <input v-model="name" type="text" maxlength="25" placeholder="请输入您的姓名" />
                    </div>
                    <div class="block3-input">
                        <img src="../assets/icon_phone.png" alt="" />
                        <input v-model="mobile" type="text" maxlength="11" placeholder="请输入您的电话" />
                    </div>
                    <button v-if="!success" class="block3-btn" @click="feedback">获取报价</button>
                    <button v-else class="block3-btn block3-btn-dis">已获取报价</button>
                </div>
            </div>
        </div>
        <div class="page-bottom">
            <span>Copyright&nbsp;&#64;&nbsp;2015-{{ year }}&nbsp;www.intbee.com&nbsp;All&nbsp;Rights&nbsp;Reserved 珠海市蜂巢数据技术有限公司 版权所有</span>
            <div>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44040202001000">公安备案号44040202001000</a>&nbsp;|&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备16020000号-3</a>&nbsp;|&nbsp;<a href="https://qiniu-static.intbee.com/imgtools/intbee-id-yyzz.jpg">营业执照</a>&nbsp;|&nbsp;<a href="https://qiniu-static.intbee.com/imgtools/intbee-id-edi.jpg">增值电信业务许可证</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
const name = ref("");
const mobile = ref("");
const success = ref(false);
const year = new Date().getFullYear();
import { toast, http, loading } from "@/lib/utils";
const feedback = () => {
  if (!name.value) {
    return toast("请输入您的姓名");
  }
  if (!mobile.value) {
    return toast("请输入您的电话");
  }
  loading.open();
  http.post("/fapi/v4/activity/save/userFeedback", {
    channel: sessionStorage.channel || "",
    message: `来源：官网底部获取报价按钮\n网站：${location.href.split("access_token")[0].split("#")[0]}`,
    mobile: mobile.value,
    name: name.value,
  }, {}, res => {
    loading.close();
    if (res.code) {
      toast(res.message || "服务开小差了，请刷新重试");
    } else {
      mobile.value = "";
      name.value = "";
      success.value = true;
      toast("合作意向提交成功，稍候有专人联系您");
    }
    console.log(res);
  });
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.page {
    text-align: left;
    background: #1a1a1a;
}

.container {
    // width: 90%;
    max-width: 1500px;
    margin: auto;
    display: flex;
    padding: 64px 72px;
}

.block-title {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    margin-bottom: 20px;
}

.block1 {
    margin-right: 48px;
}

.block1-img {
    width: 80px;
    height: 80px;
    background: #f5f5f5;
    border-radius: 4px;
}

.block2 {
    flex: 1;
}

.block2-info {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.55);
    line-height: 20px;
}

.block3 {
    text-align: right;

    > img {
        width: 92px;
        height: 40px;
        margin-bottom: 46px;
    }
}

.block3-bottom {
    display: flex;

    > * {
        margin-left: 8px;
    }
}

.block3-input {
    width: 180px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    line-height: 40px;
    padding: 0 12px;

    input {
        flex: 1;
        width: 0;
    }

    input::placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}

.block3-btn {
    width: 100px;
    height: 40px;
    background: rgba(255, 225, 77, 1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 40px;
    cursor: pointer;
}
.block3-btn-dis {
    cursor: default;
    background: rgba(255, 225, 77, 1);
    opacity: 0.8;
    color: rgba(0, 0, 0, 0.6);
}

.page-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 30px;
    padding-top: 31px;
    padding-bottom: 40px;
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.55);
    line-height: 20px;

    a {
        color: rgba(255, 255, 255, 0.55);
        text-decoration: unset;
    }
}
</style>

<style scoped lang="less">
@media screen and (max-width: 1200px) {
    .container {
        width: 100% !important;
        min-width: auto !important;
        flex-direction: column;
        padding-left: 30px !important;
    }
    .container > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: flex-start !important;
        width: 100% !important;
        margin-right: 0 !important;
        padding-top: 20px !important;
    }
    // .block2-info {
    //   // text-align: center!important;
    // }
    .block3-bottom {
        // justify-content: center!important;
        // align-items: center!important;
        flex-direction: column;
    }
    .block3-bottom > * {
        margin-bottom: 10px;
    }
    .block1-img {
        width: 160px;
        height: 160px;
    }
}
</style>
